<template>
  <div class="foot-main">
    <div class="dev-foot">
      <div class="foot-head">
        <div class="head-first-col left">
          <div class="footer-right-body">
            <div class="head-col-title">{{ introduce.categoryTitle }}</div>
            <div class="head-col-url left" v-for="(item,index) in introduce.children" :key="'sy-1-'+index">
              <a class="head-col-url-a" @click="showPlatIntr(index,item)"
                 href="javascript:void(0)">{{ item.categoryTitle }}</a>
            </div>
          </div>

        </div>
        <div class="head-second-col left">

          <div class="head-col-title">{{ introduction.categoryTitle }}</div>
          <div class="head-col-url left" v-for="(item,index) in introduction.children" :key="'sy-1-'+index">
            <a class="head-col-url-a" @click="showHelp(index,item)"
               href="javascript:void(0)">{{ item.categoryTitle }}</a>
          </div>
        </div>
        <div class="head-third-col left">
          <!-- <div class="head-third-col-style">
            <img src="../../assets/image/new-home/icon_admin.png" class="head-third-col-image">
            <span class="head-third-col-span">
              <span v-if="configForm.userNames">{{ configForm.userNames[0] }}：</span>
              <span v-if="configForm.phones">{{ configForm.phones[0] }}</span>&nbsp;/&nbsp;
              <span v-if="configForm.userNames">{{ configForm.userNames[1] }}：</span>
              <span v-if="configForm.phones">{{ configForm.phones[1] }}</span>
          </span>
          </div> -->
          <div class="head-third-col-style">
            <img src="../../assets/image/new-home/icon_phone.png" class="head-third-col-image">
            <span class="head-third-col-span">招采业务客服电话：{{ configForm.hotline }}</span>
          </div>
          <div class="head-third-col-style">
            <img src="../../assets/image/new-home/icon_phone.png" class="head-third-col-image">
            <span class="head-third-col-span">商城业务客服电话：{{ configForm.shopLine }}</span>
          </div>
          <div class="head-third-col-style">
            <img src="../../assets/image/pc/local2x.png" class="head-third-col-image">
            <span class="head-third-col-span">{{ configForm.workAddress }}</span>
          </div>
<!--          <div class="head-third-col-style">-->
<!--            <img src="../../assets/image/new-home/icon_admin.png" class="head-third-col-image">-->
<!--            <span class="head-third-col-span">湖南CA服务热线：{{ configForm.hncaPhone }}</span>-->
<!--          </div>-->
        </div>
        <div class="head-four-col left">
          <div class="head-four-col-left left">
            <div class="head-four-col-span"><span>荷云采官方微信</span></div>
            <div class="footer-center">
              <div style="width: 60px;height: 60px;">
                <img src="../../assets/image/new-home/icon_code.png" class="w-100 h-100">
              </div>
            </div>
            <div class="footer-center head-four-col-span">扫码关注</div>
          </div>
          <a target="_blank" href="https://old2.holdcg.com/#/informationDetails?id=817">
            <div class="head-four-col-right left">
              <div class="head-four-col-span"><span>三星认证标识</span></div>
              <div class="footer-center">
                <div style="width: 60px;height: 60px;">
                  <img src="../../assets/image/new-home/icon_mark.png" class="w-100 h-100">
                </div>
              </div>
              <div class="footer-center head-four-col-span">点击了解</div>
            </div>
          </a>
        </div>
      </div>
      <div class="foot-separator">
        <img src="../../assets/image/new-home/icon_separator.png" style="width: 100%">
      </div>
      <div class="foot-bottom">
        <span class="foot-bottom-left">湖南华菱电子商务有限公司旗下【荷云采】</span>
        <span
            class="foot-bottom-center">版权所有Copyright&nbsp;©&nbsp;www.holdcg.com&nbsp;All&nbsp;Rights&nbsp;Reserved</span>
        <div class="foot-bottom-right left">
          <img src="../../assets/image/pc/icon_jh.png">
          <a style="color: #666666" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43010302000530">湘公网安备
            43010302001499号 &nbsp;</a>
          <a style="color: #666666" href="https://beian.miit.gov.cn/#/Integrated/index">湘ICP备11019553号-3</a>
        </div>
      </div>
    </div>
    <div class="dev-foot-mobile">
      <div class="head-third-col">
        <!-- <div class="head-third-col-style">
          <img src="../../assets/image/new-home/icon_admin.png" class="head-third-col-image">
          <span class="head-third-col-span">
              <span v-if="configForm.userNames">{{ configForm.userNames[0] }}：</span>
              <span v-if="configForm.phones">{{ configForm.phones[0] }}</span>&nbsp;/&nbsp;
              <span v-if="configForm.userNames">{{ configForm.userNames[1] }}：</span>
              <span v-if="configForm.phones">{{ configForm.phones[1] }}</span>
          </span>
        </div> -->
        <div class="head-third-col-style">
          <img src="../../assets/image/new-home/icon_phone.png" class="head-third-col-image">
          <span class="head-third-col-span">招采业务客服电话：{{ configForm.hotline }}</span>
        </div>
        <div class="head-third-col-style">
          <img src="../../assets/image/new-home/icon_phone.png" class="head-third-col-image">
          <span class="head-third-col-span">商城业务客服电话：{{ configForm.shopLine }}</span>
        </div>
        <div class="head-third-col-style">
          <img src="../../assets/image/new-home/icon_address.png" class="head-third-col-image">
          <span class="head-third-col-span">{{ configForm.workAddress }}</span>
        </div>
<!--        <div class="head-third-col-style">-->
<!--          <img src="../../assets/image/new-home/icon_admin.png" class="head-third-col-image">-->
<!--          <span class="head-third-col-span">湖南CA服务热线：{{ configForm.hotline }}</span>-->
<!--        </div>-->
      </div>
      <div class="head-four-col">
        <div class="head-four-col-left left">
          <div class="head-four-col-span"><span>荷云采官方微信</span></div>
          <div class="footer-center">
            <div style="width: 60px;height: 60px;">
              <img src="../../assets/image/new-home/icon_code.png" class="w-100 h-100">
            </div>
          </div>
          <div class="footer-center head-four-col-span">扫码关注</div>
        </div>
        <div class="head-four-col-right left">
          <a target="_blank" href="https://old2.holdcg.com/#/informationDetails?id=817">
            <div class="head-four-col-span"><span>三星认证标识</span></div>
            <div class="footer-center">
              <div style="width: 60px;height: 60px;">
                <img src="../../assets/image/new-home/icon_mark.png" class="w-100 h-100">
              </div>
            </div>
            <div class="footer-center head-four-col-span">点击了解</div>
          </a>
        </div>
      </div>
      <div class="foot-separator">
        <img src="../../assets/image/new-home/icon_separator.png" style="width: 100%">
      </div>
      <div class="foot-bottom-tel">
        <div class="foot-bottom-left"><span>湖南华菱电子商务有限公司旗下【荷云采】</span></div>
        <div class="foot-bottom-center"><span>版权所有Copyright&nbsp;©&nbsp;www.holdcg.com&nbsp;All&nbsp;Rights&nbsp;Reserved</span>
        </div>
        <div class="foot-bottom-beian">
          <a class="foot-bottom-right"
             href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43010302000530">湘公网安备
            43010302001499号 &nbsp;</a>
          <a class="foot-bottom-right" href="https://beian.miit.gov.cn/#/Integrated/index">湘ICP备11019553号-3</a>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "cms-foot",
  data() {
    return {
      configForm: {},
      introduce: {},
      introduction: {}
    };
  },
  created() {
    /*初始化卡片*/
    this.getConfigs(203);
    /*初始化平台介绍*/
    this.selectCategory("introduce", "introduce");
    /*初始化新手入门*/
    this.selectCategory("introduction", "introduction");
  },
  methods: {
    getConfigs: function (id) {
      var that = this;
      this.$http.get(this.base + "/content/getCall.do", {
        params: {
          id: id
        }
      }).then(function (res) {
        var data = res.data.result;
        if (data) {
          that.configForm = data;
          var userNames = that.configForm.userName.split("/");
          var phones = that.configForm.phone.split("/");
          that.configForm['userNames'] = userNames;
          that.configForm['phones'] = phones;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    /*查询栏目及子栏目列表*/
    selectCategory: function (modelId, name) {
      var that = this;
      this.$http.get(this.base + "/category/get.do", {
        params: {
          modelId: modelId
        }
      }).then(function (data) {
        that[name] = data.data.result;
      }).catch(function (error) {

      })
    },
    showPlatIntr(index, item) {
      /*文章详情*/
      let json = {'path': '/introduce', 'query': {'id': item.id, 'index': index}};
      this.$router.push(json);
    },
    showHelp(index, item) {
      let json = {'path': '/help', 'query': {'id': item.id, 'index': index}};
      this.$router.push(json);
    }
  }
}
</script>

<style scoped>

/*全局*/
.foot-main {
  width: 100%;
  background: #FFFFFF;
  margin-top: 10px;

}

.foot-bottom-beian {
  line-height: 35px;
}

.footer-center {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  margin-top: 5px;
}

.dev-foot {
  width: 100%;
  height: 100%;
}

.dev-foot-mobile {
  display: none;
}

/*头部展示*/
.foot-head {
  width: 1160px;
  height: 70%;
  margin: 0 auto;
  padding-top: 20px;
}

/*第一部分*/
.head-first-col {
  width: 21%;
  height: 100%;
}

.head-col-title {
  text-align: center;
  height: 19px;
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #333333;
  line-height: 19px;
}

.head-col-url {
  font-size: 12px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  line-height: 40px;
  text-align: center;
  margin-top: 10px;
  width: 50%;
}

.head-col-url-a {
  color: #999999;
}

/*第二部分*/
.head-second-col {
  width: 21%;
  height: 100%;
}

/*第三部分*/
.head-third-col {
  width: 30%;
  height: 100%;
  margin-left: 4%;
}

.head-third-col-style {
  width: 100%;
  height: 30%;
  margin-bottom: 4%;
  display: flex;
  align-items: center;
}

.head-third-col-image {
  width: 14px;
  height: 14px;
}

.head-third-col-span {
  font-size: 12px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
  margin-left: 10px;
}

/*第四部分*/
.head-four-col {
  width: 23%;
  margin-left: 1%;
  height: 100%;
}

.head-four-col-left {
  width: 50%;
  height: 100%;
}

.head-four-col-right {
  width: 50%;
  height: 100%;
}

.head-four-col-image {
  width: 49px;
  height: 48px;
  margin: 10px 0 0 40px;
}

.head-four-col-span {
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #333333;
  line-height: 19px;
  text-align: center;
}

/*分隔符*/
.foot-separator {
  width: 1160px;
  height: 5%;
  margin: 0 auto;
}

/*底部声明*/
.foot-bottom {
  width: 1160px;
  margin: 0px auto;
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
  padding-top: 10px;
}

.foot-bottom-left {
  font-size: 12px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #666666;
  line-height: 16px;
}

.foot-bottom-center {
  font-size: 12px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #666666;
  line-height: 16px;
  margin-left: 50px;
}

.foot-bottom-right {
  font-size: 12px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #666666;
  line-height: 16px;
  margin-left: 50px;
}

@media screen and (max-width: 576px) {
  .foot-main {
    width: 100%;
  }

  .dev-foot {
    display: none;
  }

  .dev-foot-mobile {
    width: 100%;
    /*height: 370px;*/
    background: #FFFFFF;
    display: block;
  }

  /*第三部分*/
  .head-third-col {
    width: 100%;
    /*height: 100px;*/
    margin-left: 0;
    padding-top: 10px;
  }

  .head-third-col-style {
    width: 100%;
    /*height: 25%;*/
    margin-bottom: 1%;
    display: flex;
    align-items: center;
    padding-left: 10px;
  }

  .head-third-col-image {
    width: 14px;
    height: 14px;
  }

  .head-third-col-span {
    font-size: 12px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #999999;
    line-height: 21px;
    margin-left: 10px;
  }

  /*第四部分*/
  .head-four-col {
    width: 100%;
    margin-left: 0;
    height: 100px;
    margin-top: 20px;
  }

  .head-four-col-left {
    width: 50%;
    height: 100%;
  }

  .head-four-col-right {
    width: 50%;
    height: 100%;
  }

  .head-four-col-image {
    width: 49px;
    height: 48px;
    margin: 10px 0 0 70px;
  }

  .head-four-col-span {
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    line-height: 19px;
    text-align: center;
  }

  /*分隔符*/
  .foot-separator {
    width: 100%;
    height: 2px;
    margin: 0 auto;
  }

  /*底部声明*/
  .foot-bottom-tel {
    width: 100%;
    height: 20%;
    margin: 15px auto;
  }

  .foot-bottom-left {
    width: 100%;
    height: 20px;
    font-size: 12px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
    padding-left: 20px;
    padding-top: 10px;
  }

  .foot-bottom-center {
    width: 100%;
    height: 20px;
    font-size: 12px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
    margin-left: 0;
    padding-left: 20px;
    padding-top: 10px;
  }

  .foot-bottom-right {
    width: 100%;
    height: 20px;
    font-size: 12px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
    margin-left: 0;
    padding-left: 20px;
    padding-top: 10px;
  }
}

@media (min-width: 576px ) and (max-width: 768px) {
  /*全局*/
  .foot-main {
    width: 100%;
    background: #FFFFFF;
    margin-top: 10px;
  }

  /*头部展示*/
  .foot-head {
    width: 100%;
    height: 70%;
    margin: 0 auto;
    padding-top: 15px;
  }

  /*第一部分*/
  .head-first-col {
    display: none;
  }

  /*第二部分*/
  .head-second-col {
    display: none;
  }

  /*第三部分*/
  .head-third-col {
    width: 50%;
    height: 100%;
    margin-left: 10%;
  }

  .head-third-col-style {
    width: 100%;
    height: 30%;
    margin-bottom: 1%;
    display: flex;
    align-items: center;
  }

  .head-third-col-image {
    width: 14px;
    height: 14px;
  }

  .head-third-col-span {
    font-size: 10px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #999999;
    line-height: 30px;
    margin-left: 10px;
  }

  /*第四部分*/
  .head-four-col {
    width: 30%;
    margin-left: 1%;
    height: 100%;
  }

  .head-four-col-left {
    width: 50%;
    height: 100%;
  }

  .head-four-col-right {
    width: 50%;
    height: 100%;
  }

  .head-four-col-image {
    width: 49px;
    height: 48px;
    margin: 10px auto;
    display: flex;
    justify-content: center;
  }

  .head-four-col-span {
    font-size: 10px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    line-height: 19px;
    text-align: center;
  }

  /*分隔符*/
  .foot-separator {
    width: 100%;
    height: 5%;
    margin: 0 auto;
  }

  /*底部声明*/
  .foot-bottom {
    width: 100%;
    height: 20%;
    margin: 0 auto;
  }

  .foot-bottom-left {
    font-size: 11px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
    display: none;
  }

  .foot-bottom-center {
    font-size: 11px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
    margin-left: 30px;
  }

  .foot-bottom-right {
    font-size: 11px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 40px;
    margin-left: 30px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  /*全局*/
  .foot-main {
    width: 100%;
    background: #FFFFFF;
  }

  /*头部展示*/
  .foot-head {
    width: 720px;
    height: 70%;
    margin: 0 auto;
    padding-top: 15px;
  }

  /*第一部分*/
  .head-first-col {
    width: 21%;
    height: 100%;
  }

  .head-col-title {
    text-align: center;
    height: 19px;
    font-size: 11px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    line-height: 19px;
  }

  .head-col-url {
    font-size: 10px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
    margin-top: 8px;
  }

  .head-col-url-a {
    color: #999999;
  }

  /*第二部分*/
  .head-second-col {
    width: 21%;
    height: 100%;
  }

  /*第三部分*/
  .head-third-col {
    width: 30%;
    height: 100%;
    margin-left: 4%;
  }

  .head-third-col-style {
    width: 100%;
    height: 30%;
    margin-bottom: 1%;
    display: flex;
    align-items: center;
  }

  .head-third-col-image {
    width: 14px;
    height: 14px;
  }

  .head-third-col-span {
    font-size: 10px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #999999;
    line-height: 21px;
    margin-left: 10px;
  }

  /*第四部分*/
  .head-four-col {
    width: 23%;
    margin-left: 1%;
    height: 100%;
  }

  .head-four-col-left {
    width: 50%;
    height: 100%;
  }

  .head-four-col-right {
    width: 50%;
    height: 100%;
  }

  .head-four-col-image {
    width: 49px;
    height: 48px;
    margin: 10px 0 0 18px;
  }

  .head-four-col-span {
    font-size: 11px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    line-height: 19px;
    text-align: center;
  }

  /*分隔符*/
  .foot-separator {
    width: 720px;
    height: 5%;
    margin: 0 auto;
  }

  /*底部声明*/
  .foot-bottom {
    width: 720px;
    height: 20%;
    margin: 0 auto;
  }

  .foot-bottom-left {
    font-size: 11px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
  }

  .foot-bottom-center {
    font-size: 11px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
    margin-left: 30px;
  }

  .foot-bottom-right {
    font-size: 11px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
    margin-left: 30px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /*全局*/
  .foot-main {
    width: 100%;
    background: #FFFFFF;
    margin-top: 10px;
  }

  .dev-foot {
    width: 100%;
    height: 100%;
  }

  .dev-foot-mobile {
    display: none;
  }

  /*头部展示*/
  .foot-head {
    width: 960px;
    height: 70%;
    margin: 0 auto;
    padding-top: 20px;
  }

  /*第一部分*/
  .head-first-col {
    width: 21%;
    height: 100%;
  }

  .head-col-title {
    text-align: center;
    height: 19px;
    font-size: 12px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    line-height: 19px;
  }

  .head-col-url {
    font-size: 11px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    line-height: 35px;
    text-align: center;
    margin-top: 8px;
  }

  .head-col-url-a {
    color: #999999;
  }

  /*第二部分*/
  .head-second-col {
    width: 21%;
    height: 100%;
  }

  /*第三部分*/
  .head-third-col {
    width: 30%;
    height: 100%;
    margin-left: 4%;
  }

  .head-third-col-style {
    width: 100%;
    height: 30%;
    margin-bottom: 1%;
    display: flex;
    align-items: center;
  }

  .head-third-col-image {
    width: 14px;
    height: 14px;
  }

  .head-third-col-span {
    font-size: 11px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #999999;
    line-height: 30px;
    margin-left: 10px;
  }

  /*第四部分*/
  .head-four-col {
    width: 23%;
    margin-left: 1%;
    height: 100%;
  }

  .head-four-col-left {
    width: 50%;
    height: 100%;
  }

  .head-four-col-right {
    width: 50%;
    height: 100%;
  }

  .head-four-col-image {
    width: 49px;
    height: 48px;
    margin: 10px 0 0 30px;
  }

  .head-four-col-span {
    font-size: 12px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    line-height: 19px;
    text-align: center;
  }

  /*分隔符*/
  .foot-separator {
    width: 960px;
    height: 5%;
    margin: 0 auto;
  }

  /*底部声明*/
  .foot-bottom {
    width: 960px;
    height: 20%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }

  .foot-bottom-left {
    font-size: 11px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
  }

  .foot-bottom-center {
    font-size: 12px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
    margin-left: 50px;
  }

  .foot-bottom-right {
    font-size: 12px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
    margin-left: 50px;
  }
}
</style>
